// eslint-disable-next-line import/prefer-default-export
export const bankSelects = {
  data() {
    return {
      bankNames: [
        {
          name: "Banco de Chile",
          code: 1,
          id: 50,
          fintocId: "cl_banco_de_chile",
          logo: "banco-chile",
        },
        {
          name: "Banco Internacional",
          code: 2,
          id: 57,
          fintocId: null,
          logo: "banco-internacional",
        },
        {
          name: "Scotiabank Chile",
          code: 3,
          id: 62,
          fintocId: "cl_banco_scotiabank",
          logo: "scotiabank",
        },
        {
          name: "Banco de Crédito e Inversiones (BCI)",
          code: 4,
          id: 51,
          fintocId: "cl_banco_bci",
          logo: "BCI",
        },
        {
          name: "Banco Bice",
          code: 5,
          id: 45,
          fintocId: "cl_banco_bice",
          logo: "BICE",
        },
        {
          name: "Banco Santander-Chile",
          code: 6,
          id: 199,
          fintocId: "cl_banco_santander",
          logo: "santander",
        },
        {
          name: "Itaú Corpbanca",
          code: 7,
          id: 197,
          fintocId: "cl_banco_itau",
          logo: "itau",
        },
        {
          name: "Banco Security",
          code: 8,
          id: 61,
          fintocId: null,
          logo: "banco-security",
        },
        {
          name: "Banco Falabella",
          code: 9,
          id: 56,
          fintocId: "cl_banco_falabella",
          logo: "banco-falabella",
        },
        {
          name: "Banco Ripley",
          code: 10,
          id: 60,
          fintocId: "cl_banco_ripley",
          logo: "banco-ripley",
        },
        {
          name: "Banco Consorcio",
          code: 11,
          id: 198,
          fintocId: null,
          logo: "consorcio",
        },
        {
          name: "Banco Estado",
          code: 12,
          id: 54,
          fintocId: "cl_banco_estado",
          logo: "banco-estado",
        },
        {
          name: "Banco Coopeuch",
          code: 13,
          id: 201,
          fintocId: null,
          logo: "coopeuch",
        },
        // {
        //   name: "Mach",
        //   code: 14,
        //   id: 202,
        //   fintocId: null,
        //   logo: "mach",
        // },
        {
          name: "Tenpo",
          code: 15,
          id: 203,
          fintocId: null,
          logo: "tenpo",
        },
      ],
      availableBankAccountTypes: [
        { type: "Cuenta Corriente", code: 1, id: 1 },
        { type: "Cuenta Vista", code: 2, id: 2 },
        { type: "Chequera Eléctronica", code: 3, id: 3 },
        { type: "Cuenta de Ahorro", code: 4, id: 4 },
      ],
      fund_origins: [
        { type: "Rentas del Trabajo", code: 1 },
        { type: "Acuerdos Legales", code: 2 },
        { type: "Inversiones", code: 3 },
        { type: "Herencia", code: 4 },
        { type: "Seguros", code: 5 },
        { type: "Pensiones", code: 6 },
        { type: "Familiares", code: 7 },
        { type: "Venta Activos", code: 8 },
      ],
    };
  },
};
